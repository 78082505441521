import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'isomorphic-dompurify';

import css from './FandomShopPanel.module.scss';
import { useSelector } from 'react-redux';
import { FaveFandom } from '../../../models/FaveFandom';
import { getAllFandoms, getFandomAsyncInfo } from '../../../ducks/fandomData.duck';

interface Props {
  fandom: string;
}

const FandomShopPanel: React.FC<Props> = ({ fandom }) => {
  const fandoms: FaveFandom[] = useSelector(getAllFandoms);
  const fandomAsyncInfo = useSelector(getFandomAsyncInfo);

  const selectedFandom = fandoms && fandoms.find(f => f.key === fandom);

  const generateFandomLink = (fandom: FaveFandom): string => {
    return `/s/${fandom.name}?pub_fandom=${fandom.key}`;
  };

  if(fandomAsyncInfo.loading) {
    return <div>Loading...</div>
  }

  if (fandomAsyncInfo.error) {
    return <div>Could not fetch fandom data!</div>;
  }

  if (!selectedFandom || !selectedFandom.key) {
    return <div>Fandom not found!</div>;
  }

  if (selectedFandom) {
    return (
      <section className={css.root}>
        <div className={css.fandomNavigationContainer}>
          <ul className={css.fandomNavigation}>
            {fandoms.map(f => (
              <li
                key={f.id}
                className={f.name === selectedFandom.name ? css.activeFandomLink : css.fandomLink}
              >
                <Link to={generateFandomLink(f)}>{f.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={css.fandomHeroImageWrapper}>
          <img
            className={css.fandomHeroImage}
            src={selectedFandom.marketplace.background_image_url_v2}
            alt={selectedFandom.marketplace.background_image_alt_text_v2}
          />
        </div>
        <div className={css.fandomInfo}>
          <div className={css.fandomBanner}>{selectedFandom.name}</div>
          <div
            className={css.fandomText}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedFandom.marketplace.text_html_v2, {
                ALLOWED_TAGS: ['div', 'span', 'b', 'q', 'i'],
                ALLOWED_ATTR: ['style'],
              }),
            }}
          />
        </div>
      </section>
    );
  }
};

export default FandomShopPanel;
